exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-blogs-index-js": () => import("./../../../src/pages/blogs/index.js" /* webpackChunkName: "component---src-pages-blogs-index-js" */),
  "component---src-pages-contacts-index-js": () => import("./../../../src/pages/contacts/index.js" /* webpackChunkName: "component---src-pages-contacts-index-js" */),
  "component---src-pages-faqs-index-js": () => import("./../../../src/pages/faqs/index.js" /* webpackChunkName: "component---src-pages-faqs-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-online-support-index-js": () => import("./../../../src/pages/online-support/index.js" /* webpackChunkName: "component---src-pages-online-support-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-schedule-now-index-js": () => import("./../../../src/pages/schedule-now/index.js" /* webpackChunkName: "component---src-pages-schedule-now-index-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-site-generator-index-js": () => import("./../../../src/pages/site-generator/index.js" /* webpackChunkName: "component---src-pages-site-generator-index-js" */),
  "component---src-pages-terms-conditions-index-js": () => import("./../../../src/pages/terms-conditions/index.js" /* webpackChunkName: "component---src-pages-terms-conditions-index-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

